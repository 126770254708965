import assert from 'assert';
import _get from 'lodash/get';
import { put, call, take } from 'redux-saga/effects';
import { Creators, Types } from '../actions';

export default function createSagas({ documentService }) {
  assert.ok(documentService, 'documentService is required');

  const defaultLimit = 10;

  function* fetchDocumentsSaga() {
    while (true) {
      const { documentFolderSlug, searchParams } = yield take(Types.FETCH_DOCUMENTS_REQUEST);
      try {
        const offset = _get(searchParams, 'page') ? (searchParams.page - 1) * defaultLimit : 0;
        const { data } = yield call(
          [documentService, documentService.fetchDocuments],
          documentFolderSlug,
          offset,
          defaultLimit,
        );

        yield put(Creators.fetchDocumentsSuccess(data));
      } catch (e) {
        yield put(Creators.fetchDocumentsFailure(e));
      }
    }
  }

  function* fetchDocumentsPageSaga() {
    while (true) {
      const { link } = yield take(Types.FETCH_DOCUMENTS_PAGE_REQUEST);
      try {
        const { data } = yield call(
          [documentService, documentService.fetchDocumentsPage],
          link,
        );

        yield put(Creators.fetchDocumentsPageSuccess(data));
      } catch (e) {
        yield put(Creators.fetchDocumentsPageFailure(e));
      }
    }
  }

  return {
    fetchDocumentsSaga,
    fetchDocumentsPageSaga,
  };
}
